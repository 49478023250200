import promotApi from '@/api/common/promotionNotification';
import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import moment from 'moment';
import { mapState } from "vuex";
export default {
    extends: baseComponent,
    data() {
        var validateEndTime = (rule, value, callback) => {
            console.log('this.campaignForm', this.campaignForm);
            console.log('startDate', this.campaignForm.startDate);
            console.log('endDate', this.campaignForm.endDate);
            if (this.campaignForm.startDate && this.campaignForm.endDate) {
                var startDateTimeStap = moment(this.campaignForm.startDate).unix();
                var endDateTimeStap = moment(this.campaignForm.endDate).unix();
                console.log('startDateTimeStap', startDateTimeStap);
                console.log('endDateTimeStap', endDateTimeStap);
                if (startDateTimeStap >= endDateTimeStap) {
                    callback(new Error('it is finished before start'));
                }
            } 
            callback();
        };

        var validatepointBonus = (rule, value, callback) => {
            var val = parseFloat(value);
            if (val < 1) {
                
                callback(new Error('Are you doing this for fun? min is 1'));
            } 
            callback();
        };

        var validatemaxApplyTimes = (rule, value, callback) => {
            var val = parseInt(value);
            if (val < 1) {
                callback(new Error('Are you doing this for fun? min is 1'));
            }
            callback();
        };

        return {
            rules: {
                partnerId: [
                    { required: true, message: 'it mush has a Partner', trigger: 'blur' },
                ],
                campaignName: [
                    { required: true, message: 'it mush has a name', trigger: 'blur' },
                    { min: 3, max: 50, message: '3 - 50 characters.', trigger: 'blur' }
                ],
                campaignType: [
                    { required: true, message: 'it mush has a type too', trigger: 'change' }
                ],
                maxApplyTimes: [
                    { required: true, message: 'Give me a number', trigger: 'blur' },
                    { validator: validatemaxApplyTimes, trigger: 'blur' }
                ],
                pointBonus: [
                    { required: true, message: 'Give me a number.', trigger: 'blur' },
                    { validator: validatepointBonus, trigger: 'blur' }
                ],
                startDate: [
                    { type: 'date', required: true, message: 'Please pick a date', trigger: 'change' },
                    { validator: validateEndTime, trigger: 'change' }
                ],
                endDate: [
                    { type: 'date', required: true, message: 'Please pick a date', trigger: 'change' },
                    { validator: validateEndTime, trigger:'change'}
                ]
            }
        }
    },
    methods: {
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var request = Object.assign({}, this.campaignForm);
                    request.pointBonus = parseInt(request.pointBonus);
                    request.maxApplyTimes = parseInt(request.maxApplyTimes);
                    request.startDate = moment(request.startDate).utc();
                    request.endDate = moment(request.endDate).utc();
                    promotApi.createCampaign(request).then(res => {
                        if (res.data.result == 0) {
                            this.$emit("CreateSuccess", res.data.data);
                            this.showSuccessToast(`<div class="text-left">Succees</div>`);
                        } else {
                            commonAct.showError(res.data.message);
                        }
                    }).catch(err => {
                        commonAct.showError(err.message);
                    });
                } else {
                    console.log("validate failed!");
                }
            });
        },
        cancelClick() {
            this.$emit("closeSetting");
        },
        onEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var request = Object.assign({}, this.campaignForm);
                    request.pointBonus = parseInt(request.pointBonus);
                    request.maxApplyTimes = parseInt(request.maxApplyTimes);
                    request.startDate = moment(request.startDate).utc();
                    request.endDate = moment(request.endDate).utc();
                
                    promotApi.editCampaign(request).then(res => {
                        if (res.data.result == 0) {
                            this.$emit("SaveSuccess", res.data.data);
                            this.showSuccessToast(`<div class="text-left">Succees</div>`);
                        } else {
                            commonAct.showError(res.data.message);
                        }
                    }).catch(err => {
                        commonAct.showError(err.data.message);
                    });
                }
            });
        }
    },
    props: {
        currentCommand: {
            type: String,
            default: ""
        },
        campaignForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        campaignForm: function (val) {           
            if (this.currentCommand === "add" && this.campaignForm != null) this.campaignForm.staffTeam = "";
        }
    },
    components: {
        
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            staffsInfo: state => state.staffs,
            lowBalanceUser: state => state.lowBalanceUser,
        }),
        rollDisable() {
            return !(this.rolesInfo.includes('QA') || this.rolesInfo.includes('OPERATOR_MANAGER '));
        }
    },
    created() {
        if(this.currentCommand === "add") this.campaignForm.staffTeam = "";
    }
}